/*=== MAIN VARIABLES ===*/

:root {
  --clr-primary-1: hsl(0, 0%, 100%);
  --clr-primary-2: hsl(240, 7%, 15%);
  --clr-primary-3: hsl(0, 100%, 50%);
  --clr-primary-4: hsl(71, 100%, 50%);

  --clr-secondary-1: hsl(0, 0%, 50%);
  --clr-secondary-2: hsl(211, 35%, 30%);
  --clr-secondary-3: hsl(10, 100%, 40%);
  --clr-secondary-4: hsl(200, 100%, 15%);

  --ff-primary: "Poppins", sans-serif;

  --ff-all: var(--ff-primary);

  --fw-light: 300;
  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;

  --fs-14: 0.875;
  --fs-16: 1rem;
  --fs-20: 1.25rem;
  --fs-24: 1.5rem;
  --fs-36: 2.25rem;
  --fs-44: 2.75rem;
  --fs-56: 3.5rem;
  --fs-80: 5rem;

  --fs-primary-heading: var(--fs-56);
  --fs-secondary-heading: var(--fs-44);
  --fs-block-title: var(--fs-24);
  --fs-nav: var(--fs-24);
  --fs-button: var(--fs-16);
  --fs-body-bg: var(--fs-20);
  --fs-body: var(--fs-16);
  --fs-body-sm: var(--fs-14);

  --size-1: 0.5rem;
  --size-2: 0.75rem;
  --size-3: 1rem;
  --size-4: 1.5rem;
  --size-5: 2rem;
  --size-6: 2.75rem;
  --size-7: 3.5rem;
  --size-8: 5rem;
  --size-9: 7rem;
  --size-10: 10rem;

  --bp-1: 480px;
  --bp-2: 1080px;
  --bp-media: 1080px;
}

@media (max-width: 68rem) {
  :root {
    --fs-primary-heading: var(--fs-44);
    --fs-secondary-heading: var(--fs-36);
  }
}

/*=== RESET CSS ===*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root html and body defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Sticky navbar does not work with these settings

html,
body {
  min-height: 100%;
  overflow-x: hidden;
}

*/

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*=== UTILITY CLASSES ===*/

/* Hide stuff visually */

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.display-none {
  display: none;
}

/* Text color classes */

.text-primary-1 {
  color: var(--clr-primary-1);
}
.text-primary-2 {
  color: var(--clr-primary-2);
}
.text-primary-3 {
  color: var(--clr-primary-3);
}
.text-primary-4 {
  color: var(--clr-primary-4);
}

.text-secondary-1 {
  color: var(--clr-secondary-1);
}
.text-secondary-2 {
  color: var(--clr-secondary-2);
}
.text-secondary-3 {
  color: var(--clr-secondary-3);
}
.text-secondary-4 {
  color: var(--clr-secondary-4);
}

/* Background color classes */

.bg-primary-1 {
  background-color: var(--clr-primary-1);
}
.bg-primary-2 {
  background-color: var(--clr-primary-2);
}
.bg-primary-3 {
  background-color: var(--clr-primary-3);
}
.bg-primary-4 {
  background-color: var(--clr-primary-4);
}

.bg-secondary-1 {
  background-color: var(--clr-secondary-1);
}
.bg-secondary-2 {
  background-color: var(--clr-secondary-2);
}
.bg-secondary-3 {
  background-color: var(--clr-secondary-3);
}
.bg-secondary-4 {
  background-color: var(--clr-secondary-4);
}

/* Font weights classes */

.fw-bold {
  font-weight: var(--fw-bold);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-regular {
  font-weight: var(--fw-regular);
}
.fw-light {
  font-weight: var(--fw-light);
}

/* Font sizes classes */

.fs-primary-heading {
  font-size: var(--fs-primary-heading);
  line-height: 1.5;
}

.fs-secondary-heading {
  font-size: var(--fs-secondary-heading);
  line-height: 1.5;
}

.fs-block-title {
  font-size: var(--fs-block-title);
  line-height: 1.5;
}

.fs-button {
  font-size: var(--fs-button);
  line-height: 1;
}

.fs-nav {
  font-size: var(--fs-nav);
  line-height: 1.5;
}

.fs-body-bg {
  font-size: var(--fs-body-bg);
  line-height: 1.5;
}

.fs-body {
  font-size: var(--fs-body);
  line-height: 1.5;
}

.fs-body-sm {
  font-size: var(--fs-body-sm);
  line-height: 1.2;
}

.fs-14 {
  font-size: var(--fs-14);
}

.fs-16 {
  font-size: var(--fs-16);
}
.fs-24 {
  font-size: var(--fs-24);
}
.fs-36 {
  font-size: var(--fs-36);
}
.fs-44 {
  font-size: var(--fs-44);
}
.fs-56 {
  font-size: var(--fs-56);
}
.fs-80 {
  font-size: var(--fs-80);
}

@media (max-width: 68rem) {
  .fs-primary-heading {
    font-size: var(--fs-44);
  }

  .fs-secondary-heading {
    font-size: var(--fs-36);
  }
}

/* Paddings and margins classes */

.mx-auto {
  margin-inline: auto;
}

.padding-block-9 {
  padding-block: var(--size-9);
}
.padding-block-8 {
  padding-block: var(--size-8);
}
.padding-block-7 {
  padding-block: var(--size-7);
}
.padding-block-6 {
  padding-block: var(--size-6);
}
.padding-block-5 {
  padding-block: var(--size-5);
}
.padding-block-4 {
  padding-block: var(--size-4);
}
.padding-block-3 {
  padding-block: var(--size-3);
}
.padding-block-2 {
  padding-block: var(--size-2);
}
.padding-block-1 {
  padding-block: var(--size-1);
}

/* Other utility classes */

body {
  font-size: var(--fs-16);
  font-family: var(--ff-primary);
  color: var(--clr-primary-1);
  background-color: var(--clr-primary-2);
}

.container {
  --max-width: var(--bp-2);
  --container-padding: var(--size-2);

  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
}

@media (max-width: 68rem) {
  .container {
    --max-width: var(--bp-media);
    --container-padding: var(--size-1);
  }
}

a {
  text-decoration: none;
}

/*=== GENERAL STYLE - MAIN PAGE ===*/

/* Primary header - menu */

.primary-header {
  box-sizing: border-box;

  z-index: 100;

  position: sticky;
  top: 0;
}

.primary-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-3);
  z-index: 999;
}

.avica-top-logo {
  width: fit-content;
  border-radius: 15%;
}

.header-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--size-5);
  text-decoration: none;
}

@media (max-width: 68rem) {
  .header-menu {
    flex-direction: column;
    gap: var(--size-1);
  }
}

.header-menu > a {
  text-decoration: none;
  color: var(--clr-primary-1);
}

.header-menu-item {
  transition: padding-bottom 0.3s ease-in-out;
}

.header-menu-item:hover,
.header-menu-item:focus,
.header-menu-item:active {
  color: var(--clr-primary-3);
  border-bottom: 2px solid var(--clr-primary-3);
  padding-bottom: 5px;
}

.header-login-icon {
  width: 36px;
  fill: var(--clr-primary-2);
  transition: fill 0.3s ease-in-out;
}

.header-login > a {
  text-decoration: none;
  color: var(--clr-primary-1);
}

.header-login-icon:hover,
.header-login-icon:focus {
  fill: var(--clr-primary-3);
}

/* Secondary header - title */
/* url("./assets/img/bg-3-2400.jpg"); */
.secondary-header {
  background-image: linear-gradient(
    320deg,
    hsl(0deg 0% 7%) 0%,
    hsl(236deg 20% 10%) 11%,
    hsl(231deg 36% 13%) 21%,
    hsl(227deg 51% 15%) 30%,
    hsl(222deg 69% 16%) 39%,
    hsl(217deg 100% 17%) 48%,
    hsl(269deg 53% 28%) 58%,
    hsl(308deg 96% 26%) 67%,
    hsl(328deg 100% 37%) 78%,
    hsl(343deg 100% 46%) 88%,
    hsl(3deg 100% 50%) 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 550px;
  margin-bottom: -2px;
}

@media (max-width: 68rem) {
  .secondary-header {
    height: 780px;
    background-image: linear-gradient(
      320deg,
      hsl(0deg 0% 7%) 0%,
      hsl(236deg 20% 10%) 11%,
      hsl(231deg 36% 13%) 21%,
      hsl(227deg 51% 15%) 30%,
      hsl(222deg 69% 16%) 39%,
      hsl(217deg 100% 17%) 48%,
      hsl(256deg 48% 26%) 58%,
      hsl(289deg 54% 27%) 67%,
      hsl(314deg 71% 30%) 78%,
      hsl(325deg 97% 33%) 88%,
      hsl(335deg 100% 38%) 100%
    );
  }
}

.secondary-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--size-7);
  gap: var(--size-5);
  text-align: center;
}

.button {
  display: inline-flex;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 100vmax;
  padding: 1em 2em;
  color: var(--clr-primary-1);
  background-color: var(--clr-primary-3);

  margin-top: var(--size-5);
}

.button:hover,
.button:focus-visible {
  background-color: var(--clr-secondary-3);
  border: 2px solid var(--clr-primary-1);
}

/* Section title */

.section-title {
  height: auto;
}

.media-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--size-7);
  text-align: center;
  padding: var(--size-6);
}

/*
border-top: 3px solid var(--clr-primary-3);
linear-gradient(90deg, rgba(247, 24, 24, 0) 0%, rgba(255, 0, 0, 1) 50%, rgba(247, 24, 24, 0) 100%);
*/

.stripe-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 2rem;

  width: 70%;

  /* background-color: var(--clr-secondary-13); */
  border-radius: 0.5rem;

  background-image: linear-gradient(
    330deg,
    hsl(0deg 0% 7%) 0%,
    hsl(216deg 21% 13%) 11%,
    hsl(213deg 32% 18%) 22%,
    hsl(212deg 41% 24%) 33%,
    hsl(210deg 48% 30%) 44%,
    hsl(209deg 54% 35%) 56%,
    hsl(208deg 61% 41%) 67%,
    hsl(206deg 69% 45%) 78%,
    hsl(205deg 79% 49%) 89%,
    hsl(202deg 100% 50%) 100%
  );
}

@media (max-width: 68rem) {
  .media-title {
    flex-direction: column;
    gap: var(--size-5);
  }

  .stripe-1 {
    width: 90%;
  }
}

.media-title-icon {
  height: fit-content;
  border-radius: 10%;
}

.menu-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

/* AVICA main description */

.avica-main {
  display: flex;
  flex-direction: column;
}

.avica-description-top {
  padding-left: var(--size-5);
  padding-right: var(--size-5);
  text-align: center;
}

.slider-section-1 {
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: 49% 50%;
  gap: var(--size-7);
  padding: var(--size-5);
  justify-content: space-between;
  align-content: center;
  grid-template-areas: "image text";
}

.slider-section-2 {
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: 49% 50%;
  gap: var(--size-7);
  padding: var(--size-5);
  justify-content: space-between;
  align-content: center;
  grid-template-areas: "text image";
}

@media (max-width: 68rem) {
  .slider-section-1 {
    grid-auto-columns: 100%;
    grid-template-areas: "image" "text";
  }

  .slider-section-2 {
    grid-auto-columns: 100%;
    grid-template-areas: "image" "text";
  }
}

.slider-title {
  grid-area: image;

  background-image: url("./assets/img/bg-1-800.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  text-align: center;
  align-content: center;
  justify-content: center;
  padding-top: var(--size-10);
}

@media (max-width: 68rem) {
  .slider-title {
    padding-top: var(--size-5);
    padding-bottom: var(--size-5);
  }
}

.slider-text {
  grid-area: text;
}

/* AVICA vidieos */

.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--size-5);
  gap: var(--size-5);
}

.slider-video {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--size-5);
}

@media (max-width: 68rem) {
  .video {
    gap: var(--size-3);
  }

  .slider-video {
    flex-direction: column;
    gap: var(--size-3);
  }
}

.video-button {
  padding-top: var(--size-5);
  padding-bottom: var(--size-5);
}

/* AVICA footer */

.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-3);
  border-top: 3px solid var(--clr-primary-3);
}

@media (max-width: 68rem) {
  .footer-wrapper {
    flex-direction: column;
    gap: var(--size-5);
  }
}

.in4com-top-logo {
  width: fit-content;
}

.footer-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--size-3);
  text-decoration: none;
}

.footer-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--size-3);
}

.social-icon {
  width: 24px;
  aspect-ratio: 1;
  align-items: center;
  fill: var(--clr-primary-1);
}

.social-icon:hover,
.social-icon:focus {
  fill: var(--clr-primary-3);
}

/*=== GENERAL STYLE - PLATFORM PAGE ===*/

/* AVICA features */

.avica-features {
  display: flex;
  flex-direction: column;
  padding: var(--size-3);
  gap: var(--size-5);
  align-items: center;
}

.slider-2-section-1 {
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: 19% 80%;
  gap: var(--size-7);
  padding: var(--size-5);
  justify-content: center;
  align-content: center;
  grid-template-areas:
    "title title"
    "image text";
}

.slider-2-section-2 {
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: 80% 19%;
  gap: var(--size-7);
  padding: var(--size-5);
  justify-content: center;
  align-content: center;
  grid-template-areas:
    "title title"
    "text image";
}

@media (max-width: 68rem) {
  .slider-2-section-1 {
    grid-template-rows: auto auto auto;
    grid-auto-columns: 100%;
    grid-template-areas:
      "title"
      "image"
      "text";
  }

  .slider-2-section-2 {
    grid-template-rows: auto auto auto;
    grid-auto-columns: 100%;
    grid-template-areas:
      "title"
      "image"
      "text";
  }
}

.slider-2-image {
  grid-area: image;

  border: 5px solid var(--clr-secondary-4);
  border-radius: 50%;

  align-self: center;
  justify-self: center;

  transition: border 0.3s ease-in-out;
}

.slider-2-image:hover,
.slider-2-image:focus,
.slider-2-image:active {
  border: 5px dotted var(--clr-primary-3);
}

.paragraph {
  padding-left: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.slider-3-title {
  grid-area: title;
  text-align: center;

  transition: padding-bottom 0.3s ease-in-out;

  border-bottom: 2px solid var(--clr-primary-2);
}

.slider-2-title {
  grid-area: title;
  text-align: center;

  transition: padding-bottom 0.3s ease-in-out;

  border-bottom: 2px solid var(--clr-primary-2);
}

.slider-2-title:hover,
.slider-2-title:focus,
.slider-2-title:active {
  color: var(--clr-primary-3);
  border-bottom: 2px solid var(--clr-primary-3);
}

.slider-2-text {
  grid-area: text;
}

.avica-roadmap {
  padding-top: var(--size-5);
  text-align: center;
}

@media (max-width: 68rem) {
  .avica-roadmap {
    padding-left: var(--size-2);
    padding-right: var(--size-2);
  }
}

.roadmap-button {
  padding-top: var(--size-3);
  padding-bottom: var(--size-3);
}

/*=== GENERAL STYLE - USECASES PAGE ===*/

/* Usecases list */

.avica-usecases {
  display: flex;
  flex-direction: column;
  padding-top: var(--size-5);
  gap: var(--size-7);
  align-items: center;
}

/*=== GENERAL STYLE - COMPANY PAGE ===*/

/* News */

.slider {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-top: var(--size-7);
  padding-bottom: var(--size-7);
  padding-left: var(--size-7);
  padding-right: var(--size-7);

  gap: var(--size-5);

  overflow: hidden;
}

@media (max-width: 68rem) {
  .slider {
    flex-direction: column;
  }
}

.slider-content {
  padding: var(--size-5) var(--size-5);
  background: var(--clr-primary-2);
  text-align: center;
}

.slider img {
  width: 300px;
  position: relative;
  margin-inline: auto;
}

/* Clients */

.partner-logos {
  display: flex;
  flex-direction: row;
  padding-top: var(--size-7);
  padding-bottom: var(--size-7);
  gap: var(--size-5);
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 68rem) {
  .partner-logos {
    flex-direction: column;
  }
}

.partner-logo-item {
  width: 12rem;
}

/* Management */

.management {
  display: grid;
  grid-template-rows: auto;
  grid-auto-columns: 50% 49%;
  gap: var(--size-7);
  justify-content: center;
  align-content: center;
  grid-template-areas:
    "manager1 manager2"
    "button button";

  padding-top: var(--size-1);
  padding-bottom: var(--size-5);
}

@media (max-width: 68rem) {
  .management {
    grid-auto-columns: 100%;
    grid-template-areas: "manager1" "manager2" "button";
  }
}

.management-position-1 {
  grid-area: manager1;
  display: flex;
  flex-direction: column;
  gap: var(--size-5);
  align-items: center;
}

.management-position-2 {
  grid-area: manager2;
  display: flex;
  flex-direction: column;
  gap: var(--size-5);
  align-items: center;
}

.avatar-image {
  width: 150px;
  border-radius: 50%;
  border: 5px solid var(--clr-primary-1);

  transition: border 0.3s ease-in-out;
}

.avatar-image:hover,
.avatar-image:focus,
.avatar-image:active {
  border: 10px solid var(--clr-primary-3);
}

.management-button {
  grid-area: button;
  justify-self: center;
  padding-bottom: var(--size-7);
}

/*=== GENERAL STYLE - FOOTER PAGES ===*/

/* Map */

.map {
  align-self: center;
  justify-self: center;
  width: 1000px;
  height: 500px;
  padding-bottom: var(--size-9);
}

@media (max-width: 68rem) {
  .map {
    width: 500px;
    height: 400px;
  }
}

/* Contact form */

.contact-form {
  display: flex;
  flex-direction: column;
  gap: var(--size-5);
  align-items: center;
  padding-bottom: var(--size-7);
}

.contact-form > input,
.contact-form > textarea {
  width: 80%;
  outline: none;
  padding-top: var(--size-2);
  padding-bottom: var(--size-2);
  padding-left: var(--size-2);
  border-radius: 10px;
  box-sizing: border-box;
}

.contact-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: var(--size-6);
  padding-bottom: var(--size-8);
  gap: var(--size-6);
}
